















































































































import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { SearchPlugin } from "@/types";
import { tr } from "@/locale";
import { intersection } from "lodash";
import { mapGetters } from "vuex";
import { SearchEnginePage } from '@/store/types';

const ALL_KEY = "all";

const ALL_CATEGORY: Category = {
  key: ALL_KEY,
  name: tr("all"),
};

interface Category {
  key: string;
  name: string;
}

export interface SearchForm {
  valid: boolean;
  category: string;
  pattern: string;
  plugins: SearchPlugin[];
}

@Component({
  computed: {
    ...mapGetters({
      searchPlugins: "allSearchPlugins",
    }),
  },
})
export default class SearchDialogForm extends Vue {
  searchEngineState!: SearchEnginePage;
  searchPlugins!: SearchPlugin[];

  @Prop(Boolean)
  readonly loading: boolean = false;

  plugginSelectorOpen = false;

  availablePlugins: SearchPlugin[] = [];

  searchForm: SearchForm = {
    valid: false,
    category: ALL_KEY,
    pattern: "",
    plugins: [],
  };

  get hasSelectAllPlugins() {
    return this.searchForm.plugins.length === this.availablePlugins?.length;
  }

  get availableCategories() {
    if (this.hasSelectAllPlugins) {
      return [ALL_CATEGORY];
    }

    const result: Category[] = [ALL_CATEGORY, { divider: true } as any];

    const categories = intersection(
      ...this.searchForm.plugins.map(p => p.supportedCategories),
    ).map(c => ({ key: c, name: c }));
    result.push(...categories);

    return result;
  }

  toggleSelectAll() {
    this.searchForm.plugins = this.hasSelectAllPlugins ? [] : this.availablePlugins.slice();
  }

  @Watch("searchPlugins")
  searchPluginsUpdated(plugins: SearchPlugin[] | undefined | null) {
    if (!plugins) {
      this.availablePlugins = [];
    } else {
      this.availablePlugins = this.searchPlugins.filter(x => x.enabled);
      this.toggleSelectAll();
    }
  }

  @Emit("triggerSearch")
  triggerSearch(): SearchForm | void {
    if (!this.searchForm.valid) {
      return;
    }

    const plugins = this.hasSelectAllPlugins
      ? ALL_KEY
      : this.searchForm.plugins.map(p => p.name).join("|");

    const searchForm = Object.assign({}, this.searchForm, {
      plugins,
    });

    return searchForm;
  }

  @Emit("stopSearch")
  stopSearch() {
    //
  }

  @Watch("searchForm.plugins")
  onPluginChanged() {
    if (!this.availableCategories.find(c => c.key === this.searchForm.category)) {
      this.searchForm.category = ALL_KEY;
    }
  }
}
