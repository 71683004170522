/* eslint-disable @typescript-eslint/camelcase */
export default {
  lang: '繁體中文',
  auto: '自動',

  close: '關閉',
  no: '否',
  yes: '是',
  cancel: '取消',
  ok: '確定',

  start: '開始',
  stop: '停止',
  submit: '提交',
  edit: '編輯',
  delete: '刪除',
  todo: '待辦',
  resume: '恢復',
  pause: '暫停',
  force_start: '強制繼續',
  info: '資訊',
  reset: '重置',
  login: '登入',
  search: '搜索',
  refresh: '刷新',
  location: '位置',
  rename: '重新命名',
  trigger_application_shutdown: '退出qBittorrent',
  reannounce: '重新通告',
  recheck: '重新檢查',

  username: '使用者名稱',
  password: '密碼',

  name: '名稱',
  size: '大小',
  progress: '進度',
  status: '狀態',
  seeds: '做種',
  peers: '用戶',
  dl_speed: '下載速度',
  up_speed: '上傳速度',
  eta: '剩餘時間',
  ratio: '分享率',
  added_on: '添加時間',

  settings: '設定',

  logs: '日誌',
  light: '亮色',
  dark: '暗色',

  all: '全部',
  category: '分類',
  uncategorized: '未分類',
  others: '其他',
  sites: '站點',
  files: '文件',
  less: '更少',
  more: '更多',
  feed: '訂閱',
  date: '日期',
  query: '查詢',
  plugin: '插件',
  action: '操作',
  search_engine: '搜尋引擎',

  preferences: {
    change_applied: '設定已保存',
    downloads: '下載',
    adding_torrent: '添加 Torrent 時',
    create_subfolder_enabled: '為多個文件的 Torrent 創建子目錄',
    start_paused_enabled: '不要自動開始下載',
    auto_delete_mode: '完成後刪除 .torrent 文件',
    preallocate_all: '為所有文件預分配磁碟空間',
    incomplete_files_ext: '為不完整的文件添加副檔名 .!qB',
    saving_management: '保存管理',
    auto_tmm_enabled: '默認 Torrent 管理模式',
    torrent_changed_tmm_enabled: '當 Torrent 分類修改時',
    save_path_changed_tmm_enabled: '當默認保存路徑修改時',
    category_changed_tmm_enabled: '當分類保存路徑修改時',
    auto_mode: '自動',
    manual_mode: '手動',
    switch_torrent_mode_to_manual: '切換受影響的 Torrent 至手動模式',
    move_affected_torrent: '重新定位受影響的 Torrent',
    save_path: '默認保存路徑',
    temp_path: '保存未完成的 torrent 到',
    export_dir: '複製 .torrent 文件到',
    export_dir_fin: '複製下載完成的 .torrent 文件到',

    speed: '速度',
    global_rate_limits: '全局速度限制',
    alternate_rate_limits: '備用速度限制',
    alternate_schedule_enable_time: '設定備用速度限制的啟用時間',
    apply_speed_limit: '設定速度限制',
    dl_limit: '下載 (KiB/s)',
    up_limit: '上傳 (KiB/s)',
    zero_for_unlimited: '0 為無限制',
    schedule_from: '從',
    schedule_to: '到',
    scheduler_days: '時間',
    limit_utp_rate: '對 µTP 協議進行速度限制',
    limit_tcp_overhead: '對傳送總開銷進行速度限制',
    limit_lan_peers: '對本地網路用戶進行速度限制',

    connection: '連接',
    bittorrent: 'BitTorrent',

    webui: 'Web UI',
    data_update_interval: '數據更新頻率(ms)',
    webui_remote_control: 'Web 用戶界面(遠端控制)',
    ip_address: 'IP 地址',
    ip_port: '埠',
    enable_upnp: '使用我的路由器的 UPnP / NAT-PMP 功能來轉發埠',
    authentication: '驗證',
    web_ui_username: '使用者名稱',
    web_ui_password: '密碼',
    bypass_local_auth: '對本地主機上的用戶端跳過身份驗證',
    bypass_auth_subnet_whitelist: '對 IP 子網白名單中的用戶端跳過身份驗證',
    web_ui_session_timeout: '會話超時',
    web_ui_ban_duration: '禁止',
    web_ui_max_auth_fail_count: '連續失敗後禁止用戶端次數',
    web_ui_seconds: '秒',
    new_password: '更改當前的密碼...',

    display_speed_in_title: '在網頁標題顯示當前速度',
  },

  title: {
    _: '標題',
    add_torrents: '添加種子',
    delete_torrents: '刪除種子',
    set_category: '設定分類',
    edit_tracker: '編輯 Tracker',
    set_location: '修改檔案位置',
    recheck_torrents: '重新檢查種子',
  },

  label: {
    switch_to_old_ui: '切換到原版 UI',
    create_subfolder: '創建子文件夾',
    start_torrent: '開始種子',
    skip_hash_check: '跳過哈希校驗',
    in_sequential_order: '按順序下載',
    first_and_last_pieces_first: '先下載首尾文件塊',

    also_delete_files: '同時刪除文件',

    auto_tmm: '自動種子管理',

    adding: '添加…',
    reloading: '刷新中…',
    deleting: '刪除中…',
    moving: '移動中…',
    moved: '已移動',
    next: '下一步',
    back: '返回',
    confirm: '確定',
    reannounced: '已重新通告',
    rechecking: '重新檢查中…',
    dht_nodes: '%{smart_count} 節點',
  },

  msg: {
    'item_is_required': '%{item}不能為空',
  },

  dialog: {
    trigger_exit_qb: {
      title: '退出 qBittorrent',
      text: '您確定要退出qBittorrent嗎？',
    },
    add_torrents: {
      placeholder: '將種子拖到這裡上傳，\n或者點擊右邊的附件圖示來選擇。',
      hint: '每行一個連結',
    },
    delete_torrents: {
      msg: '確定要刪除選中的種子嗎？',
      also_delete_same_name_torrents: '同時刪除 %{smart_count} 個同名的種子',
    },
    set_category: {
      move: '確定要移動選中的種子到分類 %{category} 嗎？',
      reset: '確定重置選中的種子的分類嗎？',
      also_move_same_name_torrents: '同時移動 %{smart_count} 個同名的種子',
    },
    switch_locale: {
      msg: '確定要切換语言為 %{lang} 嗎？\n這將會刷新頁面。',
    },
    recheck_torrents: {
      msg: '確定要重新檢查選中的種子嗎？',
    },
    rss: {
      add_feed: '添加訂閱',
      feed_url: '訂閱 URL',
      auto_refresh: '自動刷新',
      auto_download: '自動下載',
      delete_feeds: '確定要刪除選中的訂閱嗎？',
      date_format: '%{date}（%{duration} 之前）',
    },
    rss_rule: {
      add_rule: '添加規則',
      new_rule_name: '新規則的名稱',
      delete_rule: '確定要刪除選中的規則嗎？',
      title: 'RSS 自動下載',
      rule_settings: '規則設定',

      use_regex: '使用正則',
      must_contain: '必須包含',
      must_not_contain: '必須排除',
      episode_filter: '劇集過濾',
      smart_episode: '使用智慧劇集過濾',
      assign_category: '分配分類',

      apply_to_feeds: '應用到訂閱',
    },
  },

  category_state: {
    _: '狀態',

    downloading: '下載',
    seeding: '做種',
    completed: '完成',
    resumed: '恢復',
    paused: '暫停',
    active: '活動',
    inactive: '空閒',
    errored: '錯誤',
  },

  torrent_state: {
    error: '錯誤',
    missingFiles: '文件遺失',
    uploading: '上傳中',
    pausedUP: '完成',
    queuedUP: '排隊上傳',
    stalledUP: '上傳',
    checkingUP: '上傳校驗',
    forcedUP: '強制上傳',
    allocating: '分配空間',
    downloading: '下載中',
    metaDL: '獲取資訊',
    pausedDL: '暫停下載',
    queuedDL: '排隊下載',
    stalledDL: '下載',
    checkingDL: '下載校驗',
    forceDL: '強制下載',
    checkingResumeData: '快速校驗',
    moving: '移動中',
    unknown: '未知',
  },
}
