










































































































































































































import { get, toPath, sortBy } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import Component from 'vue-class-component'
import { Prop, Watch, Emit } from 'vue-property-decorator'

import HasTask from '@/mixins/hasTask'
import api from '@/Api';
import { tr } from '@/locale'
import { RssItem, RssNode, RssTorrent } from '@/types';
import { DialogType, DialogConfig, SnackBarConfig } from '@/store/types'
import { parseDate, formatTimestamp, formatAsDuration } from '../../filters'
import RssRulesDialog from './RssRulesDialog.vue'

let darkMode: boolean;

@Component({
  components: {
    RssRulesDialog,
  },
  computed: mapState([
    'preferences',
  ]),
  methods: {
    ...mapActions([
      'asyncShowDialog',
    ]),
    ...mapMutations([
      'showSnackBar',
      'closeSnackBar',
    ]),
  },
  filters: {
    date(str: string) {
      if (!str) {
        return null
      }

      const time = parseDate(str)!
      return tr('dialog.rss.date_format', {
        date: formatTimestamp(time),
        duration: formatAsDuration(time, {minUnit: 1}),
      })
    },
  },
  directives: {
    body: {
      inserted(el, binding) {
        const doc = (el as HTMLIFrameElement).contentDocument!

        const darkCss = darkMode ? 'body{color: #fff}' : null;

        const css = `<style>
          body{font-size:12px}
          body img{max-width: 100%}
          ${darkCss}
        </style>`

        doc.head.insertAdjacentHTML('beforeend', css)
        doc.body.innerHTML = binding.value
      },
      update(el, binding) {
        if (binding.oldValue === binding.value) {
          return
        }

        const body = (el as HTMLIFrameElement).contentDocument!.body
        body.innerHTML = binding.value
        body.scrollTo({
          top: 0,
        })
      },
    },
  },
})
export default class RssDialog extends HasTask {
  @Prop(Boolean)
  readonly value!: boolean

  rssNode: RssNode | null = null
  selectNode: string | null = null
  selectArticle: RssTorrent | null = null
  showRulesDialog = false

  preferences!: any
  asyncShowDialog!: (_: DialogConfig) => Promise<string | undefined>
  showSnackBar!: (_: SnackBarConfig) => void
  closeSnackBar!: () => void

  get rssTree() {
    if (!this.rssNode) {
      return [];
    }

    return this.buildRssTree(this.rssNode!)
  }
  get selectItem() {
    if (!this.selectNode) {
      return null
    }

    const item = get(this.rssNode, this.selectNode)
    if (!item) {
      // deleted
      return null
    }

    if ('uid' in item) {
      return item as RssItem
    }

    // Folder
    return null
  }

  get selectedPath() {
    if (!this.selectNode) {
      return null
    }

    return toPath(this.selectNode!).map(p => {
      return p.replace('\\\'', '\'').replace('\\\\', '\\');
    }).join('\\');
  }

  sortArticles(articles: RssTorrent[]) {
    return sortBy(articles, (it) => new Date(it.date || 0)).reverse();
  }

  isItemLoading(row: any) {
    const item = row.item.item
    return item && item.isLoading
  }
  
  getRowIcon(row: any) {
    const item = row.item.item
    if (item) {
      if (item.isLoading) {
        return 'mdi-refresh'
      } else if (item.hasError) {
        return 'mdi-alert'
      }

      return 'mdi-rss'
    }

    return row.open ? 'mdi-folder-open' : 'mdi-folder';
  }

  buildRssTree(node: RssNode, parent?: string) {
    const result: any = [];
    function escapeKey(key: string) {
      const escaped = key.replace('\\', '\\\\').replace('\'', '\\\'');
      return `['${escaped}']`
    }

    for (const [key, value] of Object.entries(node)) {
      const path = parent ? (parent + escapeKey(key)) : escapeKey(key)

      if ('uid' in value) {
        result.push({
          path,
          name: key,
          item: value,
        })
      } else {
        result.push({
          path,
          name: key,
          children: this.buildRssTree(value, path),
        })
      }
    }

    return result;
  }

  async addRssItem() {
    const input = await this.asyncShowDialog({
      text: tr('dialog.rss.feed_url'),
      type: DialogType.Input,
    })

    if (!input) {
      return
    }

    this.showSnackBar({
      text: tr('label.adding'),
    })

    try {
      await api.addRssFeed(input);
    } catch (e) {
      this.showSnackBar({
        text: e.response ? e.response.data : e.message,
      })
      return
    }
    await this.runTask();

    this.closeSnackBar();
  }

  async renameRssItem() {
    const input = await this.asyncShowDialog({
      text: tr('name'),
      type: DialogType.Input,
      value: this.selectedPath!,
    })

    if (!input) {
      return
    }

    this.showSnackBar({
      text: tr('label.moving'),
    })

    try {
      await api.moveRssFeed(this.selectedPath!, input);
    } catch (e) {
      this.showSnackBar({
        text: e.response ? e.response.data : e.message,
      })
      return
    }
    await this.runTask();

    this.closeSnackBar();
  }

  async deleteRssItem() {
    const confirm = await this.asyncShowDialog({
      text: tr('dialog.rss.delete_feeds'),
      type: DialogType.OkCancel,
    })

    if (!confirm) {
      return
    }

    this.showSnackBar({
      text: tr('label.deleting'),
    })

    try {
      await api.removeRssFeed(this.selectedPath!);
    } catch (e) {
      this.showSnackBar({
        text: e.response ? e.response.data : e.message,
      })
      return
    }
    await this.runTask();

    this.closeSnackBar();
  }

  async refreshRssItem() {
    await api.refreshRssFeed(this.selectedPath!);
    await this.runTask();
  }

  async changePreference(key: string, value: any) {
    await api.setPreferences({
      [key]: value,
    })
  }

  async fetchRssItems() {
    this.rssNode = await api.getRssItems()
  }

  @Emit()
  downloadTorrent(article: RssTorrent) {
    return article.torrentURL
  }

  @Watch('selectNode')
  onSelectNodeChanged() {
    this.selectArticle = null
  }

  created() {
    darkMode = this.$vuetify.theme.dark
    this.setTaskAndRun(this.fetchRssItems, 5000)
  }

  @Emit('input')
  closeDialog() {
    return false
  }
}
