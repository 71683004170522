

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Group } from '../types'

@Component
export default class FilterGroup extends Vue {
  @Prop()
  readonly group!: Group

  model = this.group.model
  selected: string | null = null

  created() {
    const s = this.$store.getters.config.filter[this.group.select];
    if (this.group.children.some(child => child.key === s)) {
      this.selected = s;
    } else {
      this.select(null);
    }
    if (this.model == null) {
      this.model = this.selected != null;
    }
  }

  select(key: string | null) {
    this.selected = this.selected === key ? null : key;
    this.$store.commit('updateConfig', {
      key: 'filter',
      value: {
        [this.group.select]: this.selected,
      },
    });
  }

  isFontIcon(icon: string) {
    return icon.startsWith('mdi-');
  }
}
