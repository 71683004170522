























































































































































































import { sumBy } from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import api from '../Api';
import buildInfo from '@/buildInfo';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Torrent, ServerState } from '@/types';


@Component({
  filters: {
    connectionIcon(status: string) {
      const statusMap: any = {
        connected: 'check-network',
        firewalled: 'minus-network',
        disconnected: 'close-network',
      };
      return statusMap[status];
    },
    connectionIconColor(status: string) {
      const statusMap: any = {
        connected: 'success',
        firewalled: 'warning',
        disconnected: 'error',
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState({
      info(state: any) {
        return this.isDataReady ? state.mainData.server_state : null;
      },
    }),
    ...mapGetters([
      'isDataReady',
      'allTorrents',
    ]),
  },
})
export default class Footer extends Vue {
  @Prop(Boolean)
  readonly phoneLayout!: boolean

  app: any = null
  speedLimited = false

  buildInfo = buildInfo

  info!: ServerState
  isDataReady!: boolean
  allTorrents!: Torrent[]

  get totalSize() {
    return sumBy(this.allTorrents, 'size');
  }

  get speedModeBind() {
    if (this.speedLimited) {
      return {
        class: 'speed-limited',
        color: 'warning',
      };
    }

    return {
      class: null,
      color: 'success',
    };
  }

  get topLayoutClass() {
    const v = this.phoneLayout;
    if (v) {
      return ['in-drawer', 'flex-column'];
    }

    return ['mx-4', 'justify-space-between'];
  }

  async getAppInfo() {
    let resp = await api.getAppVersion();
    const version = resp.data;

    resp = await api.getApiVersion();
    const apiVersion = resp.data;

    this.app = {
      version, apiVersion,
    };
  }

  async toggleSpeedLimitsMode() {
    this.speedLimited = !this.speedLimited;
    await api.toggleSpeedLimitsMode();
  }

  created() {
    if (!this.isDataReady) {
      return;
    }

    this.speedLimited = this.info.use_alt_speed_limits;
    this.getAppInfo();
  }

  @Watch('isDataReady')
  onDataReady(v: boolean) {
    if (v && this.app === null) {
      this.getAppInfo();
    }
  }

  @Watch('info.use_alt_speed_limits')
  onSpeedLimitChanged (v: boolean) {
    this.speedLimited = v;
  }
}
