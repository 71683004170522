






































































































import { chain } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import api from '@/Api';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { Torrent } from '../../types';


@Component({
  computed: {
    ...mapGetters(['allTorrents']),
  },
})
export default class EditTrackerDialog extends Vue {
  @Prop(Array)
  readonly value!: Torrent[]

  step = 1
  valid = false
  submitting = false
  torrents: Torrent[] = []
  search = ''
  replace = ''
  toEdit: any[] = []
  currentIndex = 0

  allTorrents!: Torrent[]

  created() {
    this.torrents = this.value
  }

  get phoneLayout() {
    return this.$vuetify.breakpoint.xsOnly;
  }
  get canNext() {
    if (this.step === 1 && this.valid) {
      return true;
    }
    if (this.step === 2 && this.toEdit.length > 0) {
      return true;
    }
    if (this.step === 3 && !this.submitting) {
      return true;
    }
    return false;
  }

  @Emit('input')
  closeDialog() {
    return []
  }

  calcResults(): any[] {
    const regex = new RegExp(this.search);

    return chain(this.torrents)
      .map(({ tracker, hash, name }) => {
        const newUrl = tracker.replace(regex, this.replace);
        return newUrl === tracker ? null : {
          hash,
          name,
          origUrl: tracker,
          newUrl,
        };
      }).compact().value();
  }

  back() {
    if (this.step === 1) {
      this.closeDialog();
      return;
    }
    this.step--;
  }

  async foward() {
    if (this.step === 1) {
      this.toEdit = this.calcResults();
      this.step++;
      return;
    }
    if (this.step === 3) {
      this.closeDialog();
      return;
    }

    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.step++;

    this.currentIndex = 0;

    for (const item of this.toEdit) {
      await api.editTracker(item.hash, item.origUrl, item.newUrl);
      this.currentIndex++;
    }

    this.submitting = false;
  }
}
