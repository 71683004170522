






































































































import Vue from 'vue'
import {Preferences} from '@/types'
import {Component} from 'vue-property-decorator'
import {mapActions, mapGetters} from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters({
      preferences: 'allPreferences',
    }),
  },
  methods: {
    ...mapActions({
      updatePreferencesRequest: 'updatePreferencesRequest',
    }),
    convertToKB(value: number): string {
      return (value / 1024).toString()
    },
    convertToBytes(value: number): number {
      return value * 1024
    },
  },
})
export default class SpeedSettings extends Vue {
  preferences!: Preferences

  updatePreferencesRequest!: (_: any) => void

  changeSettings(property: string, value: string | boolean | number) {
    this.updatePreferencesRequest({[property]: value})
  }

  updateSchedulerFrom(event: string) {
    const strings = event.split(':')
    this.updatePreferencesRequest({'schedule_from_hour': strings[0], 'schedule_from_min': strings[1]})
  }

  updateSchedulerTo(event: string) {
    const strings = event.split(':')
    this.updatePreferencesRequest({'schedule_to_hour': strings[0], 'schedule_to_min': strings[1]})
  }
}
