

























































































































import Vue from 'vue'
import {Preferences} from '@/types'
import {Component} from 'vue-property-decorator'
import {mapActions, mapGetters} from 'vuex'
import PreferenceRow from './PreferenceRow.vue'
import { tr } from '@/locale'

@Component({
  components: {
    PreferenceRow,
  },
  computed: {
    ...mapGetters({
      preferences: 'allPreferences',
    }),
  },
  methods: {
    ...mapActions({
      updatePreferencesRequest: 'updatePreferencesRequest',
    }),
  },
})
export default class DownloadSettings extends Vue {
  preferences!: Preferences
  torrentAction = [tr('preferences.switch_torrent_mode_to_manual'), tr('preferences.move_affected_torrent')]
  torrentMode = [tr('preferences.auto_mode'), tr('preferences.manual_mode')]

  updatePreferencesRequest!: (_: any) => void

  changeSettings(property: string, value: string | boolean) {
    this.updatePreferencesRequest({[property]: value})
  }
}
