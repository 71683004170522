





























import { SearchEnginePage } from "@/store/types";
import { SearchPlugin } from "@/types";
import Vue from "vue";
import Component from "vue-class-component";
import { mapActions, mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      searchEngineState: "searchEngine",
    }),
  },
  methods: {
    ...mapActions({
      togglePluginAvailabilityAction: "togglePluginAvailability",
      updatePluginsRequest: "updatePluginsRequest",
    }),
  },
})
export default class PluginsManager extends Vue {
  searchEngineState!: SearchEnginePage;
  togglePluginAvailabilityAction!: (_: any) => void;
  updatePluginsRequest!: () => void;

  togglePluginAvailability(plugin: SearchPlugin) {
    this.togglePluginAvailabilityAction(plugin);
  }

  updatePlugins() {
    this.updatePluginsRequest();
  }
}
