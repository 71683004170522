












































import { map, merge, cloneDeep } from 'lodash';
import { codeToFlag, isWindows } from '../../utils';
import api from '../../Api';
import { formatSize } from '../../filters';
import BaseTorrentInfo from './baseTorrentInfo';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  filters: {
    networkSpeed(speed: number) {
      if (speed === 0) {
        return null;
      }

      return `${formatSize(speed)}/s`;
    },
    networkSize(size: number) {
      if (size === 0) {
        return null;
      }

      return formatSize(size);
    },
  },
})
export default class Peers extends BaseTorrentInfo {
  @Prop(String)
  readonly hash!: string

  headers = [
    { text: 'IP', value: 'ip' },
    { text: 'Connection', value: 'connection' },
    { text: 'Flags', value: 'flags' },
    { text: 'Client', value: 'client' },
    { text: 'Progress', value: 'progress' },
    { text: 'DL Speed', value: 'dl_speed' },
    { text: 'Downloaded', value: 'downloaded' },
    { text: 'UP Speed', value: 'up_speed' },
    { text: 'Uploaded', value: 'uploaded' },
    { text: 'Relevance', value: 'relevance' },
    { text: 'Files', value: 'files' },
  ]

  peersObj: any = null
  rid: number | null = null
  isWindows: boolean = isWindows

  get peers() {
    return map(this.peersObj, (value, key) => merge({}, value, { key }));
  }

  codeToFlag(code: string) {
    if (code) {
      return codeToFlag(code);
    }

    return {};
  }

  async getPeers() {
    const resp = await api.getTorrentPeers(this.hash, this.rid || undefined);
    this.rid = resp.rid;

    if (resp.full_update) {
      this.peersObj = resp.peers;
    } else {
      const tmp: any = cloneDeep(this.peersObj);
      if (resp.peers_removed) {
        for (const key of resp.peers_removed) {
          delete tmp[key];
        }
      }
      this.peersObj = merge(tmp, resp.peers);
    }
  }

  fetchInfo() {
    return this.getPeers()
  }

  startTask() {
    this.setTaskAndRun(this.doTask, 2000)
  }
}
