







































































































































































import { isEmpty, isEqual, pull, cloneDeep } from 'lodash'
import Vue from 'vue'
import Component from 'vue-class-component';

import { tr } from '@/locale'
import { Prop, Emit, Watch } from 'vue-property-decorator';
import { RssRule, Category, RssNode } from '../../types';
import api from '../../Api';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { DialogConfig, DialogType, SnackBarConfig } from '../../store/types';

@Component({
  computed: {
    ...mapGetters([
      'allCategories',
    ]),
  },
  methods: {
    ...mapMutations([
      'showSnackBar',
      'closeSnackBar',
    ]),
    ...mapActions([
      'asyncShowDialog',
    ]),
  },
})
export default class RssRulesDialog extends Vue {
  @Prop(Boolean)
  readonly value!: boolean
  @Prop()
  readonly rssNode!: RssNode

  rssRules: {[key: string]: RssRule} | null = null
  selectedRuleName: string | null = null

  allCategories!: Category[]

  asyncShowDialog!: (_: DialogConfig) => Promise<string | undefined>
  showSnackBar!: (_: SnackBarConfig) => void
  closeSnackBar!: () => void

  get selectedRule(): RssRule {
    if (!this.selectedRuleName || !(this.selectedRuleName in this.rssRules!)) {
      return {} as RssRule
    }

    return this.rssRules![this.selectedRuleName]
  }
  set selectedRule(v: RssRule) {
    this.rssRules![this.selectedRuleName!] = v
  }

  get categoryItems() {
    const uncategory: Category = {
      key: '',
      name: tr('uncategorized'),
    }

    return [uncategory, ...this.allCategories].map(c => {
      return {
        text: c.name,
        value: c.key,
      }
    })
  }
  get rssItems() {
    return this.buildRssItems(this.rssNode)
  }

  hasSelectSite(url: string) {
    return this.selectedRule.affectedFeeds.includes(url)
  }

  selectSite(url: string, enabled: boolean) {
    const rule = cloneDeep(this.selectedRule)
    const feeds = rule.affectedFeeds

    if (enabled) {
      feeds.push(url)
    } else {
      pull(feeds, url)
    }

    this.selectedRule = rule
  }

  editRule(key: keyof RssRule, value: any) {
    const rule: any = cloneDeep(this.selectedRule)
    rule[key] = value

    this.selectedRule = rule
  }

  buildRssItems(node: RssNode) {
    let result: any[] = []

    for (const [key, value] of Object.entries(node)) {
      if ('uid' in value) {
        result.push({
          text: key,
          value: value.url,
        })
      } else {
        result = result.concat(this.buildRssItems(value))
      }
    }

    return result
  }

  async fetchRssRules() {
    this.rssRules = await api.getRssRules()
  }

  async addRssRule() {
    const name = await this.asyncShowDialog({
      text: tr('dialog.rss_rule.new_rule_name'),
      type: DialogType.Input,
    })

    if (!name) {
      return
    }

    this.showSnackBar({
      text: tr('label.adding'),
    })

    await api.setRssRule(name);
    this.fetchRssRules()

    this.closeSnackBar();
  }

  async deleteRssRule() {
    const input = await this.asyncShowDialog({
      text: tr('dialog.rss_rule.delete_rule'),
      type: DialogType.OkCancel,
    })

    if (!input) {
      return
    }

    this.showSnackBar({
      text: tr('label.deleting'),
    })

    await api.removeRssRule(this.selectedRuleName!);
    this.fetchRssRules()

    this.closeSnackBar();
  }

  @Emit('input')
  closeDialog() {
    return false
  }

  created() {
    this.fetchRssRules()
  }

  @Watch('selectedRule', {deep: true})
  async onSelectedRuleChanged(v: RssRule, old: RssRule) {
    if (isEmpty(old) || isEmpty(v)) {
      // just select rule
      return
    }

    if (isEqual(v, old)) {
      return
    }

    await api.setRssRule(this.selectedRuleName!, v)
    await this.fetchRssRules()
  }
}
