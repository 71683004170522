





































































import { defineComponent, reactive, toRefs } from '@vue/composition-api';

import api from '@/Api';
import { useStore } from '@/store';

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore();
    const data = reactive({
      valid: false,
      submitting: false,
      showPassword: false,
      loginError: null,
      baseUrl: store.getters.config.baseUrl || location.href,
      params: {
        username: '',
        password: '',
      },
      form: null,
    });

    const submit = async () => {
      if (data.submitting) {
        return;
      }

      if (!(data.form as any).validate()) {
        return;
      }

      data.submitting = true;
      try {
        const resp = await api.login(data.params, data.baseUrl);

        if (resp === 'Ok.') {
          api.changeBaseUrl(data.baseUrl);

          store.commit('updateConfig', {
            key: 'baseUrl',
            value: data.baseUrl,
          });
          store.commit('updateNeedAuth', false);

          emit('input', false);
          return;
        }

        data.loginError = resp;
      } catch (e) {
        data.loginError = e.message;
      }

      data.submitting = false;
    }

    return {
      ...toRefs(data),
      submit,
    }
  },
});
