









































































































































































































import { isNil } from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters, mapMutations } from 'vuex';

import api from '../Api';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Preferences, Category } from '../types';
import { AddFormState } from '@/store/types';

/* eslint-disable @typescript-eslint/camelcase */
const defaultParams = {
  urls: '',
  category: '',
  paused: false,
  savepath: '',
  skip_checking: false,
  root_path: false,
  sequentialDownload: false,
  firstLastPiecePrio: false,
  autoTMM: false,
};
/* eslint-enable @typescript-eslint/camelcase */

@Component({
  computed: {
    ...mapState({
      pasteUrl: 'pasteUrl',
      prefs: 'preferences',
      state: 'addForm',
    }),
    ...mapGetters({
      allCategories: 'allCategories',
    }),
  },
   methods: {
    ...mapMutations([
      'closeAddForm',
      'openAddForm',
    ]),
  },
})
export default class AddForm extends Vue {
  valid = false
  files: FileList | [] = []
  defaultParams = defaultParams
  userParams = {}
  error: string | null = null
  submitting = false
  showMore = false

  state!: AddFormState;
  pasteUrl!: string | null
  prefs!: Preferences
  allCategories!: Category[]

  $refs!: {
    form: any;
    file: any;
    fileZone: HTMLElement;
  }

  openAddForm!: () => void;
  closeAddForm!: () => void;

  get params() {
    return Object.assign({}, defaultParams, this.userParams);
  }
  get phoneLayout() {
    return this.$vuetify.breakpoint.xsOnly;
  }
  get categoryItems() {
    return this.allCategories.map(c => ({ text: c.name, value: c.key }));
  }
  get defaultPath() {
    if (this.params.autoTMM && this.params.category) {
      const category = this.allCategories.find(c => {
        return c.key === this.params.category;
      });
      
      if (!category) {
        return this.params.category;
      }

      return category.savePath || category.name
    }

    return this.defaultParams.savepath;
  }

  created() {
    defaultParams.paused = this.prefs.start_paused_enabled;
    /* eslint-disable-next-line @typescript-eslint/camelcase */
    defaultParams.root_path = this.prefs.create_subfolder_enabled;
    defaultParams.savepath = this.prefs.save_path;
    defaultParams.autoTMM = this.prefs.auto_tmm_enabled;
  }

  mounted() {
    this.$refs.fileZone.addEventListener('drop', this.onDrop, true);
  }

  @Watch('state', {deep: true})
  onStateUpdate(state: AddFormState) {
    if (state.downloadItem) {
      this.setParams('urls', state.downloadItem.url);
    }
  }

  beforeDestroy() {
    this.$refs.fileZone.removeEventListener('drop', this.onDrop, true);
  }

  setParams(key: keyof typeof defaultParams, value: any) {
    if (isNil(value) || value === defaultParams[key]) {
      Vue.delete(this.userParams, key);
    } else {
      Vue.set(this.userParams, key, value);
    }
  }

  async submit() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.error = null;
    let files;
    if (this.files.length) {
      ({ files } = this);
      Vue.delete(this.userParams, 'urls');
    } else {
      files = null;
    }

    try {
      const resp = await api.addTorrents(this.userParams, files);

      if (resp !== 'Ok.') {
        this.error = resp;
      }
    } catch (e) {
      this.error = e.message;
    }

    this.submitting = false;

    if (this.error) {
      return;
    }

    this.closeAddForm();

    Vue.delete(this.userParams, 'urls');
    this.files = [];

    this.$refs.form.resetValidation();
  }

  selectFiles() {
    const input = this.$refs.file.$el.querySelector('input[type=file]');
    input.click();
  }

  onDrop(e: DragEvent) {
    const transfer = e.dataTransfer!;
    const { files } = transfer;
    if (!files.length) {
      return;
    }

    e.preventDefault();
    this.files = files;
  }

  @Watch('pasteUrl', {immediate: true})
  onPasteUrl(v: string) {
    if (!v) {
      return;
    }

    if (!this.state.isOpen) {
      Vue.set(this.userParams, 'urls', v);
      this.openAddForm();
    }
  }

  @Watch('files')
  onFilesChange() {
    this.$refs.form.validate();
  }
}
