

























































import Vue from 'vue';
import { mapGetters } from 'vuex';

import api from '@/Api';
import { findSameNamedTorrents } from '@/utils';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { Torrent } from '../../types';

@Component({
  computed: {
    ...mapGetters(['allTorrents']),
  },
})
export default class ConfirmSetCategoryDialog extends Vue {
  @Prop(Array)
  readonly value!: Torrent[]

  @Prop(String)
  readonly category!: string

  moveSameNamed = false
  submitting = false
  torrents: Torrent[] = []
  sameNamedTorrents: Torrent[] = []

  allTorrents!: Torrent[]

  created() {
    this.torrents = this.value;
    this.sameNamedTorrents = findSameNamedTorrents(this.allTorrents, this.torrents);
  }

  get phoneLayout() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  @Emit('input')
  closeDialog() {
    return []
  }

  async submit() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    let torrentsToMove;
    if (this.moveSameNamed) {
      torrentsToMove = this.torrents.concat(this.sameNamedTorrents);
    } else {
      torrentsToMove = this.torrents;
    }
    const hashes = torrentsToMove.map((t: any) => t.hash);
    await api.setTorrentsCategory(hashes, this.category);

    this.closeDialog();
  }
}
