



































































































import Vue from 'vue';
import TorrentInfo from './TorrentInfo.vue';
import TorrentContent from './TorrentContent.vue';
import Trackers from './Trackers.vue';
import Peers from './Peers.vue';
import Panel from './Panel.vue';
import Component from 'vue-class-component';
import { Prop, Emit, PropSync } from 'vue-property-decorator';
import { Torrent } from '../../types';

@Component({
  components: {
    TorrentInfo,
    TorrentContent,
    Trackers,
    Peers,
    Panel,
  },
})
export default class InfoDialog extends Vue {
  @Prop(Array)
  readonly value!: Torrent[]

  @PropSync('tab', String)
  tabSync!: string

  torrents!: Torrent[]

  created() {
    this.torrents = this.value
  }

  get phoneLayout() {
    return this.$vuetify.breakpoint.xsOnly;
  }
  get dialogWidth() {
    return this.phoneLayout ? '100%' : '80%';
  }

  @Emit('input')
  closeDialog() {
    return false
  }
}
