/* eslint-disable @typescript-eslint/camelcase */
export default {
  lang: 'English',
  auto: 'Auto',

  close: 'Close',
  no: 'No',
  yes: 'Yes',
  cancel: 'Cancel',
  ok: 'OK',

  start: 'Start',
  stop: 'Stop',
  submit: 'Submit',
  edit: 'Edit',
  delete: 'Delete',
  todo: 'To Do',
  resume: 'Resume',
  pause: 'Pause',
  force_start: 'Force Start',
  info: 'Info',
  reset: 'Reset',
  login: 'Login',
  search: 'Search',
  refresh: 'Refresh',
  location: 'Location',
  rename: 'Rename',
  trigger_application_shutdown: 'Exit qBittorrent',
  reannounce: 'Reannounce',
  recheck: 'Recheck',

  username: 'Username',
  password: 'Password',

  name: 'Name',
  size: 'Size',
  progress: 'Progress',
  status: 'Status',
  seeds: 'Seeds',
  peers: 'Peers',
  dl_speed: 'DL Speed',
  up_speed: 'UP Speed',
  eta: 'ETA',
  ratio: 'Ratio',
  added_on: 'Added On',

  settings: 'Settings',
  logs: 'Logs',
  light: 'Light',
  dark: 'Dark',

  all: 'All',
  category: 'Category |||| Categories',
  uncategorized: 'Uncategorized',
  others: 'Others',
  sites: 'Sites',
  files: 'Files',
  less: 'Less',
  more: 'More',
  feed: 'Feed',
  date: 'Date',
  query: 'Query',
  plugin: 'Plugin |||| Plugins',
  action: 'Action |||| Actions',
  search_engine: 'Search engine',
  usage: 'usage',
  plugin_manager: 'Plugin manager',
  update_plugins: 'Update plugins',

  preferences: {
    change_applied: 'New preferences saved',
    downloads: 'Downloads',
    adding_torrent: 'When adding a torrent',
    create_subfolder_enabled: 'Create subfolder for torrents with multiple files',
    start_paused_enabled: 'Do not start the download automatically',
    auto_delete_mode: 'Delete .torrent files afterwards',
    preallocate_all: 'Pre-allocate disk space for all files',
    incomplete_files_ext: 'Append .!qB extension to incomplete files',
    saving_management: 'Saving Management',
    auto_tmm_enabled: 'Default Torrent Management Mode',
    torrent_changed_tmm_enabled: 'When Torrent Category changed',
    save_path_changed_tmm_enabled: 'When Default Save Path changed',
    category_changed_tmm_enabled: 'When Category Save Path changed',
    auto_mode: 'Automatic',
    manual_mode: 'Manual',
    switch_torrent_mode_to_manual: 'Switch affected torrent to manual mode',
    move_affected_torrent: 'Relocate affected torrents',
    save_path: 'Default Save Path',
    temp_path: 'Keep incomplete torrents in',
    export_dir: 'Copy .torrent files to',
    export_dir_fin: 'Copy .torrent files for finished downloads to',

    speed: 'Speed',
    global_rate_limits: 'Global Rate Limits',
    alternate_rate_limits: 'Alternative Rate Limits',
    alternate_schedule_enable_time: 'Schedule the use of alternative rate limits',
    apply_speed_limit: 'Rate Limits Settings',
    dl_limit: 'Download (KiB/s)',
    up_limit: 'Upload (KiB/s)',
    zero_for_unlimited: '0 means unlimited',
    schedule_from: 'From',
    schedule_to: 'To',
    scheduler_days: 'When',
    limit_utp_rate: 'Apply rate limit to µTP protocol',
    limit_tcp_overhead: 'Apply rate limit to transport overhead',
    limit_lan_peers: 'Apply rate limit to peers on LAN',

    connection: 'Connections',
    bittorrent: 'BitTorrent',

    webui: 'Web UI',
    data_update_interval: 'Data Update Interval (ms)',
    webui_remote_control: 'Web User Interface (Remote control)',
    ip_address: 'IP address',
    ip_port: 'Port',
    enable_upnp: 'Use UPnP / NAT-PMP to forward the port from my router',
    authentication: 'Authentication',
    web_ui_username: 'Username',
    web_ui_password: 'Password',
    bypass_local_auth: 'Bypass authentication for clients on localhost',
    bypass_auth_subnet_whitelist: 'Bypass authentication for clients on localhost',
    web_ui_session_timeout: 'Session timeout',
    web_ui_max_auth_fail_count: 'Ban client after consecutive failures',
    web_ui_ban_duration: 'ban for',
    web_ui_seconds: 'seconds',
    new_password: 'Change current password...',

    display_speed_in_title: 'Display download speed in page title',
  },

  title: {
    _: 'Title',
    add_torrents: 'Add Torrents',
    delete_torrents: 'Delete Torrents',
    set_category: 'Set Category',
    edit_tracker: 'Edit Tracker',
    set_location: 'Set Location',
    recheck_torrents: 'Recheck Torrents',
  },

  label: {
    switch_to_old_ui: 'Switch to old UI',
    create_subfolder: 'Create subfolder',
    start_torrent: 'Start torrent',
    skip_hash_check: 'Skip hash check',
    in_sequential_order: 'In sequential order',
    first_and_last_pieces_first: 'First and last pieces first',

    also_delete_files: 'Also delete files',

    auto_tmm: 'Auto TMM',

    adding: 'Adding…',
    reloading: 'Reloading…',
    deleting: 'Deleting…',
    moving: 'Moving…',
    moved: 'Moved',
    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    reannounced: 'Reannounced',
    rechecking: 'Rechecking…',
    dht_nodes: '%{smart_count} node |||| %{smart_count} nodes',
    base_url: 'Base URL',
  },

  msg: {
    item_is_required: '%{item} is required',
  },

  dialog: {
    trigger_exit_qb: {
      title: 'Exit qBittorrent',
      text: 'Are you sure you want to exit qBittorrent?',
    },
    add_torrents: {
      placeholder: 'Upload torrents by drop them here,\nor click attachment button at right to select.',
      hint: 'One link per line',
    },
    delete_torrents: {
      msg: 'Are you sure to delete selected torrents from transfer list?',
      also_delete_same_name_torrents: 'Also delete one same named torrent |||| Also delete %{smart_count} same named torrents',
    },
    set_category: {
      move: 'Are you sure to move selected torrents to category %{category}?',
      reset: 'Are you sure to reset category of selected torrents?',
      also_move_same_name_torrents: 'Also move one same named torrent |||| Also move %{smart_count} same named torrents',
    },
    switch_locale: {
      msg: 'Are you sure to switch language to %{lang}?\nThis action will reload page.',
    },
    recheck_torrents: {
      msg: 'Are you sure want to recheck torrents?',
    },
    rss: {
      add_feed: 'Add Feed',
      feed_url: 'Feed URL',
      auto_refresh: 'Auto Refresh',
      auto_download: 'Auto Download',
      delete_feeds: 'Are you sure to delete selected feeds?',
      date_format: '%{date} (%{duration} ago)',
    },
    rss_rule: {
      add_rule: 'Add Rule',
      new_rule_name: 'The name of the new rule',
      delete_rule: 'Are you sure to delete selected rule?',
      title: 'RSS Downloader',
      rule_settings: 'Rule Settings',

      use_regex: 'Use Regex',
      must_contain: 'Must Contain',
      must_not_contain: 'Must Not Contain',
      episode_filter: 'Episode Filter',
      smart_episode: 'Use Smart Episode Filter',
      assign_category: 'Assign Category',

      apply_to_feeds: 'Apply Rule to Feeds',
    },
  },

  category_state: {
    _: 'State',

    downloading: 'Downloading',
    seeding: 'Seeding',
    completed: 'Completed',
    resumed: 'Resumed',
    paused: 'Paused',
    active: 'Active',
    inactive: 'Inactive',
    errored: 'Errored',
  },

  torrent_state: {
    error: 'error',
    missingFiles: 'missingFiles',
    uploading: 'uploading',
    pausedUP: 'pausedUP',
    queuedUP: 'queuedUP',
    stalledUP: 'stalledUP',
    checkingUP: 'checkingUP',
    forcedUP: 'forcedUP',
    allocating: 'allocating',
    downloading: 'downloading',
    metaDL: 'metaDL',
    pausedDL: 'pausedDL',
    queuedDL: 'queuedDL',
    stalledDL: 'stalledDL',
    checkingDL: 'checkingDL',
    forceDL: 'forceDL',
    checkingResumeData: 'checkingResumeData',
    moving: 'moving',
    unknown: 'unknown',
  },
}
