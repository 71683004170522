import { render, staticRenderFns } from "./TorrentContent.vue?vue&type=template&id=360390b3&scoped=true&"
import script from "./TorrentContent.vue?vue&type=script&lang=ts&"
export * from "./TorrentContent.vue?vue&type=script&lang=ts&"
import style0 from "./TorrentContent.vue?vue&type=style&index=0&id=360390b3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360390b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VProgressCircular,VTreeview})
