








































import { throttle } from 'lodash';
import Vue from 'vue';
import { mapMutations } from 'vuex';

import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { ConfigPayload } from '@/store/types';

@Component({
  methods: {
    ...mapMutations([
      'updateConfig',
    ]),
  },
})
export default class MainToolbar extends Vue {
  @Prop(Boolean)
  readonly value!: boolean

  updateConfig!: (_: ConfigPayload) => void

  focusedSearch = false

  get searchQuery() {
    return this.$store.getters.config.filter.query;
  }

  get phoneLayout() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get searchBarExpanded() {
    return this.phoneLayout && (this.focusedSearch || !!this.searchQuery);
  }

  @Emit('input')
  toggle() {
    return !this.value;
  }

  onSearch = throttle(async (v: string) => {
    // avoid input lag
    await this.$nextTick();
    this.updateConfig({
      key: 'filter',
      value: {
        query: v,
      },
    });
  }, 400)
}
