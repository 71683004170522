












































import api from '@/Api';
import Component from 'vue-class-component';
import HasTask from '../../mixins/hasTask';
import { Prop, Emit } from 'vue-property-decorator';

@Component({
  filters: {
    formatType(type: number) {
      const map: any = {
        1: 'N',
        2: 'I',
        4: 'W',
        8: 'C',
      };
      return map[type];
    },
    typeColor(type: number) {
      const map: any = {
        1: null,
        2: 'info--text',
        4: 'warning--text',
        8: 'error--text',
      };
      return map[type];
    },
  },
})
export default class LogsDialog extends HasTask {
  @Prop(Boolean)
  readonly value!: boolean

  logs: any[] = []

  get dialogWidth() {
    return this.$vuetify.breakpoint.smAndDown ? '100%' : '70%';
  }
  get phoneLayout() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  @Emit('input')
  closeDialog() {
    return false
  }

  async getLogs() {
    const lastId = this.logs.length ? this.logs[this.logs.length - 1].id : -1;
    const logs = await api.getLogs(lastId);

    if (this.destroy) {
      return;
    }

    if (logs.length) {
      this.logs = this.logs.concat(logs);

      await this.$nextTick();

      (this.$refs.end as HTMLElement).scrollIntoView();
    }
  }

  created() {
    this.setTaskAndRun(this.getLogs)
  }
}
